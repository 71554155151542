const facebook = {
    appId: "449924903284446",
    cookie: true,
    xfbml: true,
    version: "v12.0",
    scope: "email",
};

export const facebookConfig = facebook;

const google = {
    client_id:
        "955868809195-vfgl7ijhtbni95pnra6bbevqhtltvclq.apps.googleusercontent.com",
    scope: "profile email",
};

export const googleConfig = google;
